import { render, staticRenderFns } from "./main.vue?vue&type=template&id=9082db2e&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./main.vue?vue&type=style&index=0&id=9082db2e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/opt/yamal.ru/src/components/base/header/index.vue').default,AffiliatorSale: require('/opt/yamal.ru/src/components/affiliator/sale.vue').default,ServiceMainList: require('/opt/yamal.ru/src/components/service/main/list.vue').default,RzContainer: require('/opt/yamal.ru/node_modules/razlet-ui/src/components/container/index.vue').default})
