
import IconLogo from '~/components/icon/logo';

export default {
  name: 'base-logo',
  components: {
    IconLogo,
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    withoutText: {
      type: Boolean,
      default: false,
    },
  },
};
