export function sendGlobalEvent (name, data = {}) {
  const event = new CustomEvent(name, {
    detail: data,
    bubbles: true,
    cancelable: true,
    composed: false,
  });
  window.dispatchEvent(event);
}

export function listenGlobalEvent (name, callback) {
  window.addEventListener(name, callback);
}

export function removeGlobalEvent (name) {
  window.removeEventListener(name, () => {});
}