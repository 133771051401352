
import bgDesktop from '~/assets/img/index/bg-2.webp';
import bgMobile from '~/assets/img/index/bg-2m.webp';
import globalEvents from '~/mixins/global-events';

export default {
  name: 'section-main',
  mixins: [globalEvents],
  data () {
    return {
      bgDesktop,
      bgMobile,
    };
  },
};
