
export default {
  name: 'service-main-card',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    cardStyles() {
      return this.isHovered 
        ? `background: ${this.service.bgHover}; color: ${this.service.colorHover};` 
        : `background: ${this.service.bg}; color: ${this.service.color};`;
    },
  },
};
