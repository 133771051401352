import { render, staticRenderFns } from "./sale.vue?vue&type=template&id=65d54256&"
import script from "./sale.vue?vue&type=script&lang=js&"
export * from "./sale.vue?vue&type=script&lang=js&"
import style0 from "./sale.vue?vue&type=style&index=0&id=65d54256&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzDottedLoader: require('/opt/yamal.ru/node_modules/razlet-ui/src/components/dotted-loader/index.vue').default,RzContainer: require('/opt/yamal.ru/node_modules/razlet-ui/src/components/container/index.vue').default})
