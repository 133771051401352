
export default {
  name: 'service-main-list',
  data() {
    return {
      services: [
        {
          name: 'tickets',
          icon: 'icon-ticket',
          image: 'ticket_hover.webp',
          color: '#FFFFFF',
          colorHover: '#FFFFFF',
          bg: '#E82A2A',
          bgHover: '#1E4D7E',
          href: '/sale',
          fill: '#FFFFFF',
        },
        {
          name: 'park',
          icon: 'icon-schedule',
          image: 'park_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#1BA1ED',
          href: '/about/aviapark',
          fill: '#003E92',
        },
        {
          name: 'map',
          icon: 'icon-map',
          image: 'map_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#0E9543',
          href: '/about/map',
          fill: '#003E92',
        },
        {
          name: 'schedule',
          icon: 'icon-tablo',
          image: 'tablo_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#F2BD09',
          href: '/passazhiram/schedule',
          fill: '#003E92',
        },
        /* {
          name: 'registration',
          icon: 'icon-registration',
          image: 'registration_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#404040',
          href: '/registration',
          fill: '#003E92',
        },
        {
          name: 'tablo',
          icon: 'icon-tablo',
          image: 'tablo_hover.webp',
          color: '#222222',
          colorHover: '#FFFFFF',
          bg: '#FFFFFF',
          bgHover: '#FFDC14',
          href: '/tablo',
          fill: '#003E92',
        }, */
      ],
    };
  },
};
